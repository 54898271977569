import { createBrowserRouter, Outlet } from 'react-router-dom';

import Container from '@/components/dispatcher/Container';
import ComponentWrapper from '@/config/ComponentWrapper';
import { routes } from '@/path_defs';

import AdminScheduled from './pages/admin/Scheduled';
import CcScheduled from './pages/careCoordinator/Scheduled';
import Community from './pages/dispatcher/Community';
import DispatcherScheduled from './pages/dispatcher/Scheduled';

const router = createBrowserRouter([
  {
    path: '/',
    Component: () => ComponentWrapper(Outlet),
    children: [
      {
        path: 'dispatcher',
        Component: Container,
        children: [
          {
            path: 'community',
            Component: Community,
          },
          {
            path: 'scheduled',
            Component: DispatcherScheduled,
          },
        ],
      },
      {
        path: 'trips',
        Component: Container,
        children: [
          {
            path: 'scheduled',
            Component: CcScheduled,
          },
        ],
      },
      {
        path: 'admin',
        Component: Container,
        children: [
          {
            path: 'trips',
            children: [
              {
                path: 'scheduled',
                Component: AdminScheduled,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type Router = typeof router;

export default router;

type ReactRails = {
  unmountComponents: () => void;
};

const ReactRailsUJS = require('react_ujs') as ReactRails; // eslint-disable-line global-require, @typescript-eslint/no-require-imports

/**
 * This is a turbolinks/react-router shim that is triggered when the user clicks on a link tracked by turbolinks (aka plain ole anchor tags).
 * The most common use case is when the user clicks on a link on the nav bar.
 *
 * Depending on the current page & the target url, we will either:
 * 1. Cancel the HTTP request and navigate using react router.
 * 2. Let the HTTP request continue and navigate using react router.
 * 3. Unmount all react components.
 * 4. Do nothing.
 *
 * For number 1, we can safely cancel the HTTP request because two react pages send the same HTML doc (an empty body with react `root` div).
 *
 * For number 2, React router does not monitor the URL, meaning if you navigate outside of a react-router `Link` component, react-router will not
 * be aware of the route change. Failing to update react router on the target url will result in rendering the previous page or a 404 (depending
 * on if the previous page was a react page). For this case, we cannot cancel the HTTP request because the root div is not present in the current page.
 *
 * For number 3, we need to unmount all react components because the user is navigating to a page that does not contain react. Failing to do so
 * will result in a memory leak. react-rails will hold a reference to the previous page's components, however, the next time a react page is rendered,
 * a new instance of `App` will be mounted.
 *
 * Potential user flows:
 * 1. Current page is a react page & target url is a react page:
 *    - Cancel the HTTP request and navigate using react router.
 * 2. Current page is not a react page & target url is a react page:
 *    - Let the HTTP request continue and navigate using react router.
 * 3. Current page is a react page & target url is not a react page:
 *    - Unmount components.
 * 4. Current page is not a react page & target url is not a react page:
 *    - Do nothing.
 */
document.addEventListener('turbolinks:before-visit', ((
  e: Event & { data: { url: string } },
) => {
  const target = new URL(e.data.url);

  if (target.origin !== window.location.origin) {
    // Let Turbolinks handle cross-origin navigation or browser default
    return;
  }

  const current = window.location.pathname;
  const targetPathWithParams = target.pathname + target.search + target.hash;

  const isCurrentPageReact = routes.includes(current);
  const isTargetPageReact = routes.includes(target.pathname);

  const reactToReact = isCurrentPageReact && isTargetPageReact;
  const reactToNonReact = isCurrentPageReact && !isTargetPageReact;
  const nonReactToReact = !isCurrentPageReact && isTargetPageReact;

  if (reactToReact) {
    e.preventDefault();
    void router.navigate(targetPathWithParams); // eslint-disable-line no-void

    return;
  }

  if (nonReactToReact) {
    void router.navigate(targetPathWithParams); // eslint-disable-line no-void

    return;
  }

  if (reactToNonReact) {
    ReactRailsUJS.unmountComponents();
  }
}) as EventListener);
